import React from "react";
import Purpose from "../Assets/initiative.jpeg";
import SolutionStep from "./SolutionStep";
import "../Styles/Initiative.css";

function Initiative() {
    return (
        <div className="initiative-section" id="initiative">
            <div className="initiative-image-content">
                <img src={Purpose} alt="Purpose" className="initiative-image1" />
            </div>

            <div className="initiative-text-content">
                <h3 className="initiative-title">
                    <span>Our Initiatives</span>
                </h3>

                <SolutionStep
                    title=""
                    description="Meals & Moments serves as the cornerstone of our organization, inspired by the desire to alleviate isolation felt by individuals who are unable to leave their homes. Our mission is to bridge the gap between communities and individuals. In partnership with Meals on Wheels, we deliver meals and foster connections during our home visits. We promote meaningful relationships beyond these connections as well, through the exchange of letters, engaging video calls, and various other avenues."
                />

                <SolutionStep
                    title=""
                    description="In our commitment to building a supportive community, we extend our reach to empower the aging adult population in rural areas. Through collaborative efforts with local senior centers and in coordination with fire departments, we have identified prevalent healthcare issues affecting the well-being of our aging community. Leveraging the expertise of our dedicated volunteers, we have meticulously crafted educational curricula aimed at empowering individuals with knowledge. Our comprehensive programs cover preventive measures, enhanced health-safety protocols, and strategies for addressing common medical issues. Topics range from discussions on social determinants of health, including combating self-isolation, to maintaining healthy blood pressure levels."
                />

                <SolutionStep
                    title=""
                    description="Presently, our initiatives thrive at two centers: Bugbee Senior Center in White River Junction, VT and the Upper Valley Senior Center in Lebanon, NH. We’ve collaborated with the Lebanon Fire Department as well, crafting instructional materials vital to our healthcare classes. At Bugbee, the groundwork for the Meals & Moments program is underway, with successful implementation and instruction of our healthcare classes. Active participation in their weekly coffee shops has also allowed us to not only deliver meals but also engage with members, establishing meaningful connections. Simultaneously, at the Upper Valley Senior Center, we are diligently advancing our Meals & Moments initiative, delivering meals and fostering connections. Our outreach with the center extends to teaching healthcare classes as well. Looking ahead to 2024, our vision encompasses the expansion of both impactful programs, integrating them into our community fabric as we work to expand to new senior centers, and eventually create new organizational chapters in new towns."
                />
            </div>
        </div>
    );
}

export default Initiative;
