import React from "react";
import StripePic from "../Assets/Stripe.png";
import "../Styles/Stripe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

function Stripe() {

    const handleStripe = () => {
        const a = document.createElement('a')
        a.href = 'https://donate.stripe.com/8wM4jPawd86ydCEdQQ'
        a.target = '_blank'
        a.click()
        a.remove()
    };

    return (
        <div className="stripe-container">
            <div>
                <div className="stripe-section">
                    <div className="stripe-image-content">
                        <h3 className="stripe-title">
                            Donate
                        </h3>
                        <p className="stripe-contribute">We at Dhiya Foundation are overjoyed to share that, as of October 9, 2023, the Internal Revenue Service has officially recognized us as a tax-exempt organization under Section 501(c)(3) of the Internal Revenue Code (IRC). This achievement marks a significant milestone in our journey of making a positive impact.<br />
                            Why This Matters for You, Our Generous Donors:
                            Tax-Deductible Contributions: Your invaluable donations to Dhiya Foundation are now tax-deductible. This means when you contribute to our cause, you can also benefit from a reduction in your taxable income, in accordance with IRC Section 170.
                        </p>
                    </div>

                    <div className="stripe-text-content">
                        <button
                            className="text-appointment-btn"
                            type="button"
                            onClick={handleStripe}
                        >
                            <FontAwesomeIcon icon={faMoneyCheckDollar} /> Donate
                        </button><br />
                        <img src={StripePic} alt="Purpose" className="stripe-stripe" />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Stripe;
