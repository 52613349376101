import React from "react";
import Purpose from "../Assets/purpose.jpeg";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="ourstory">
      <div className="about-image-content">
        <img src={Purpose} alt="Purpose" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>Our Purpose</span>
        </h3>
        <p className="about-description">
          At Dhiya Foundation, our purpose is to cultivate a healthier and more empathetic future by proactively addressing healthcare disparities. We are passionately dedicated to improving the social determinants of healthcare through empowerment, representation, and the power of empathy, with a particular focus on bridging critical gaps in rural and elderly care.<br /><br />Our journey begins in New Hampshire and Vermont, where we wholeheartedly commit to empowering individuals to take charge of their well-being through educational resources and strategic partnerships. We firmly believe in the importance of empathy, as we aim to create an environment where understanding and compassion are central to success, fostering a more inclusive and culturally diverse medical landscape.<br /><br />Through these collective efforts, our overarching purpose is to revolutionize healthcare, alleviate existing disparities. Driven by empathy and compassion, we aim to create lasting improvements in healthcare access and quality for all.
        </p>
      </div>
    </div>
  );
}

export default About;
