import React from "react";

import "../Styles/Home.css";

import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import About from "../Components/About";
import Story from "../Components/Story";
import Initiative from "../Components/Initiative";
import Mission from "../Components/Mission";
import Footer from "../Components/Footer";
import Roles from "../Components/Roles";
import Donate from "../Components/Donate";
import Contact from "../Components/Contact";
import Effort from "../Components/Effort";
import Service from "../Components/Service";
import Stripe from "../Components/Stripe";

function Home() {
  return (
    <div className="home-section">
      <Navbar />
      <Hero />
      <Info />
      <Effort />
      <About />
      <Story />
      <Mission />
      <Roles />
      <Service />
      <Initiative />
      <Donate />
      <Stripe />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
