import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import Logo from '../Assets/logo.png'

function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/">
          <img className="logo-image" src={Logo} alt="Logo" />
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <a href="#home" className="navbar-links">
            Home
          </a>
        </li>
        <li>
          <a href="#ourstory" className="navbar-links">
            Our Story
          </a>
        </li>
        <li>
          <a href="#mission" className="navbar-links">
            Get Involved
          </a>
        </li>
        <li>
          <a href="#service" className="navbar-links">
            Seek Services
          </a>
        </li>
        <li>
          <a href="#donate" className="navbar-links">
            Donate
          </a>
        </li>
        <li>
          <a href="#contact" className="navbar-links">
            Contact Us
          </a>
        </li>
      </ul>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <a onClick={openNav} href="#home">
              Home
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#ourstory">
              Our Story
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#mission">
              Get Involved
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#service">
              Seek Services
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#donate">
              Donate
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
